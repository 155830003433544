<template>
  <div class="home">
    <header>
      <img class="header-bgi" src="@/assets/top.png" alt="" />
      <div class="top">
        <img src="@/assets/logo_white.png" alt="" srcset="" />
        <a-button @click="visible = true">联系我们</a-button>
      </div>
      <div class="total">
        <h1>燃烧生命，助力客户成功！</h1>
        <span
          >对其他公司而言，您的项目只是一个任务，但对我们而言，那是生死之战</span
        >
        <div class="tip">
          7X24小时提供服务保障，节假日全年无休，用生命守护您的业务
        </div>
        <div class="total-list">
          <div class="total-item" v-for="item in topList" :key="item.Id">
            <div class="content">
              <div class="real-content">
                <span class="number">{{ item.value }}</span>
                <span>{{ item.unit }}</span>
              </div>
            </div>
            <div>{{ item.label }}</div>
          </div>
        </div>
      </div>
      <img class="center-bgi" src="@/assets/stars.png" alt="" />
      <div class="service">
        <h2>提供专业IT技术服务</h2>
        <span>快速为您解决问题</span>
        <div class="service-list">
          <div
            class="service-item"
            v-for="(item, ind) in serviceList"
            :key="item.Id"
          >
            <img :src="require(`@/assets/spt${ind + 1}.png`)" alt="" />
            <div>{{ item.serviceName }}</div>
            <span>{{ item.describe }}</span>
          </div>
        </div>
      </div>
    </header>
    <body>
      <div class="advantage-list">
        <div
          class="advantage"
          v-for="(item, ind) in advantageList"
          :key="item.Id"
        >
          <img
            width="90px"
            :src="require(`@/assets/adv${ind + 1}.png`)"
            alt=""
          />
          <div class="right">
            <div class="advantage-name">{{ item.adNmae }}</div>
            <div>{{ item.describe }}</div>
          </div>
        </div>
      </div>
      <div class="think-list">
        <h2>面向客户的思考</h2>
        <span>用最低的成本助力客户成功</span>
        <div
          class="think-row"
          v-for="outind in Math.ceil(thinkList.length / 2)"
          :key="outind + thinkList[outind].Id"
        >
          <div
            class="think"
            v-for="(item, ind) in thinkList.slice(2 * outind - 2, 2 * outind)"
            :key="item.Id"
            @mouseenter="item.ifhover = true"
            @mouseleave="item.ifhover = false"
          >
            <div class="think-name">{{ item.thinkName }}</div>
            <img
              width="24"
              v-if="item.ifhover"
              :src="require(`@/assets/think${outind * 2 - 1 + ind}_hover.svg`)"
              alt=""
            />
            <img
              v-else
              width="24"
              :src="require(`@/assets/think${outind * 2 - 1 + ind}.svg`)"
              alt=""
            />
            <span>{{ item.describe }}</span>
          </div>
        </div>
      </div>
      <div class="flow-list">
        <h2>我们的服务流程</h2>
        <span>快速、专业、塑造品质</span>
        <div class="flows">
          <div class="flow" v-for="(item, ind) in flowList" :key="item.Id">
            <div class="flow-top">
              {{ "0" + (ind + 1) }}
              <img
                width="40"
                :src="require(`@/assets/flow${ind + 1}.png`)"
                alt=""
              />
            </div>
            <div class="flow-name">{{ item.flowName }}</div>
            <div>{{ item.describe }}</div>
            <a-icon v-if="ind !== flowList.length - 1" type="arrow-right" />
          </div>
        </div>
      </div>
    </body>
    <footer>
      <h2>他们都选择了我们</h2>
      <div
        class="add-comp-row"
        v-for="outind in Math.ceil(addCompList.length / 4)"
        :key="outind + addCompList[outind]"
      >
        <div
          class="add-comps"
          v-for="(item, ind) in addCompList.slice(4 * outind - 4, 4 * outind)"
          :key="item + outind + ind"
        >
          <img :src="require(`@/assets/${item}.png`)" alt="" />
        </div>
      </div>
      <div class="logo-row">
        <img src="@/assets/logo.png" alt="" />
      </div>
      <div class="police-row">
        <a href="https://beian.miit.gov.cn/#/Integrated/index">
          <img src="@/assets/police.png" alt="" />
          京公网安备 京ICP备2021039885号-1
        </a>
      </div>
    </footer>
    <a-modal
      dialogClass="callModal"
      width="70%"
      :visible="visible"
      :closable="false"
      @cancel="visible = false"
    >
      <div class="content">
        <div class="left">
          <img width="110%" src="@/assets/call.png" alt="" />
        </div>
        <div class="right">
          <div class="concat">联 系 我 们</div>
          <a-input placeholder="您的称呼" size="large" />
          <a-input placeholder="联系方式" size="large" />
          <a-input placeholder="企业名称" size="large" />
          <a-radio>
            我已阅读并同意<span>《用户协议》</span>和<span>《隐私政策》</span>
          </a-radio>
          <a-button type="primary" size="large" @click="submit"
            >极速触达</a-button
          >
        </div>
      </div>
      <template slot="footer"> </template>
    </a-modal>
  </div>
</template>

<script>
export default {
  name: "HomeView",
  data() {
    return {
      topList: [
        { Id: "top1", label: "研发项目锤炼", value: "30", unit: "+" },
        { Id: "top2", label: "售后响应速度", value: "5", unit: "min" },
        { Id: "top3", label: "低于市场开发成本", value: "20", unit: "%" },
        { Id: "top4", label: "极致的交付效率", value: "3", unit: "天" },
      ],
      serviceList: [
        {
          Id: "backend",
          serviceName: "服务端开发",
          describe: "服务端API开发、工具开发",
        },
        {
          Id: "test",
          serviceName: "系统专业性测试",
          describe: "安全测试、性能测试、渗透测试、数据安全合规评估",
        },
        {
          Id: "service",
          serviceName: "运维维保服务",
          describe: "代运维CI/CD、运维加购梳理&设计、故障辅助排错、节假日值班",
        },
        {
          Id: "product",
          serviceName: "产品需求与原型策划",
          describe: "服务端API开发、工具开发",
        },
        {
          Id: "frontend",
          serviceName: "前端开发",
          describe: "Web/H5/小程序开发，静态展示页、功能系统网站等",
        },
      ],
      advantageList: [
        {
          Id: "update",
          adNmae: "缩短开发周期降本增效",
          describe:
            "快速理解及梳理业务，设计最优的技术方案，对风险敏锐感知力和项目掌控力，让项目开发周期大大缩短，用别人60%的时间极速完成项目开发，省时、高效。",
        },
        {
          Id: "time",
          adNmae: "响应极速有效解决问题",
          describe:
            "我们会与您站在一起，对业务进行深度摸排和理解，在出业务故障时能够极速响应，帮助客户快速解决问题，7*24小时随时都在。",
        },
      ],
      thinkList: [
        {
          Id: "early",
          thinkName: "早期创业项目",
          describe:
            "组建技术团队：成本高、招聘周期长和管理成本高及分散精力。交给我们，为您量身定做，以规范的开发流程来实施项目，用最低的费用，最少的时间开发产品1.0的版本去验证商业模式。",
          ifhover: false,
        },
        {
          Id: "grow",
          thinkName: "成长型项目",
          describe:
            "进入高速通道：事多、压力大，人手不足，项目裹足不前。将项目不重要的部分交给我们，我们助力业务提速，让您专注做最核心的部分。",
          ifhover: false,
        },
        {
          Id: "top",
          thinkName: "平峰型项目",
          describe:
            "进入平峰期：成长遇阻，维持增长即可，但又不能释放过多资源和精力到这些项目中。将项目交给我们，我们帮助您用最低的研发成本、时间交付成本来开发实施项目，让您专注第二曲线的增长。",
          ifhover: false,
        },
        {
          Id: "rebuild",
          thinkName: "重构型项目",
          describe:
            "进入重构阶段：任务重且不能中断，人手又不足，既要满足现有业务的运转，也要提炼增长。将业务交给我们，站在您的角度深度把握业务，并且帮助您快速分解任务并进行开发，我们将开发完成的结果给到您，辅助您提升业务承载能力",
          ifhover: false,
        },
      ],
      flowList: [
        {
          Id: "talk",
          flowName: "沟通需求",
          describe:
            "根据您的需求，开放模块及功能，评估时间和成本，设计开发所需的功能模块，并跟进客户确认目标及里程碑。",
        },
        {
          Id: "contract",
          flowName: "签订合同",
          describe: "双方意见一致后，与您签订纸质合同并支付首期款，进行合作。",
        },
        {
          Id: "work",
          flowName: "开始工作",
          describe: "按里程碑计划提交进度，规范开发，把控风险。",
        },
        {
          Id: "finish",
          flowName: "完成交付",
          describe:
            "在约定内完成并成功上线及确认验收后，交付全部验收材料，支付尾款完成服务。",
        },
      ],
      addCompList: [
        "addlogo1",
        "addlogo2",
        "addlogo3",
        "addlogo4",
        "addlogo5",
        "addlogo7",
        "addlogo8",
        "addlogo9",
      ],
      visible: false,
    };
  },
  mounted() {
    this.resizeHeight();
    window.addEventListener("resize", this.resizeHeight);
  },
  methods: {
    resizeHeight() {
      setTimeout(() => {
        let imgHeight =
          document.getElementsByClassName("header-bgi")[0].offsetHeight;
        let headerDom = document.getElementsByTagName("header")[0];
        headerDom.style.height = imgHeight + "px";
      });
    },
    submit() {
      this.$message.success("发送成功");
      this.visible = false;
    },
  },
};
</script>

<style lang="less">
.home {
  padding: 0 15%;
  position: relative;
  header {
    * {
      color: #fff;
    }
    .header-bgi {
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
      z-index: -1;
    }
    .top {
      display: flex;
      justify-content: space-between;
      padding: 20px 0;
      .ant-btn {
        background-color: transparent;
      }
    }
    .total {
      padding-top: 14vh;
      text-align: left;
      width: 60%;
      h1 {
        font-size: 42px;
        margin-bottom: 4vh;
        & + span {
          font-size: 15px;
        }
      }
      .tip {
        padding-top: 10px;
        font-size: 12px;
      }
      .total-list {
        margin-top: 14vh;
        display: flex;
        justify-content: space-between;
        .total-item {
          position: relative;

          border-radius: 50%;
          display: flex;
          flex-direction: column;
          align-items: center;
          .content {
            width: 100px;
            height: 100px;
            position: relative;
            flex-shrink: 0;
            margin-bottom: 16px;
            padding: 10px;
            border: 1px solid rgba(255, 255, 255, 0.2);
            border-radius: 50%;
            &::before,
            &::after {
              content: "";
              width: 10px;
              height: 10px;
              position: absolute;
              left: -5px;
              top: 50%;
              transform: translateY(-50%);
              background-color: rgba(255, 255, 255, 0.3);
              border-radius: 50%;
            }
            &::after {
              left: auto;
              right: -5px;
            }
            .real-content {
              width: 100%;
              height: 100%;
              background-color: rgba(255, 255, 255, 0.1);
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
              &::before,
              &::after {
                content: "";
                width: 10px;
                height: 10px;
                position: absolute;
                left: 50%;
                top: -5px;
                transform: translateX(-50%);
                background-color: rgba(255, 255, 255, 0.3);
                border-radius: 50%;
              }
              &::after {
                top: auto;
                bottom: -5px;
              }
              .number {
                font-size: 30px;
                font-weight: bold;
                & + span {
                  font-size: 16px;
                  transform: translateY(4px);
                }
              }
            }
            & + div {
              font-size: 12px;
            }
          }
        }
      }
    }
    .center-bgi {
      width: 80%;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 66vh;
    }
    .service {
      width: 100%;
      margin-top: 25vh;
      text-align: center;
      h2 {
        font-size: 30px;
      }
      .service-list {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        .service-item {
          flex: 1;
          background-color: #fff;
          display: flex;
          flex-direction: column;
          align-items: center;
          border-radius: 6px;
          margin: 0 10px;
          padding: 20px;
          div {
            color: #000;
            font-size: 18px;
            font-weight: 700;
            padding: 10px 0;
          }
          span {
            color: #666;
            font-size: 14px;
          }
        }
      }
    }
  }
  body {
    background-color: #fff;
    width: 100vw;
    transform: translateX(-15%);
    .advantage-list {
      padding: 60px 15%;
      display: flex;
      justify-content: space-between;
      .advantage {
        display: flex;
        width: 46%;
        border-radius: 6px;
        align-items: center;
        padding: 0 20px;
        background: linear-gradient(
          158.2deg,
          rgb(76, 161, 251) 0%,
          rgb(27, 96, 250) 100%
        );
        &:hover {
          background: linear-gradient(
            145.28deg,
            rgb(105, 158, 253),
            rgb(77, 125, 249)
          );
        }
        .right {
          margin-left: 20px;
          color: #fff;
          text-align: left;
          .advantage-name {
            font-size: 18px;
            margin-bottom: 10px;
            & + div {
              font-size: 12px;
            }
          }
        }
        &:nth-child(2) {
          img {
            width: 120px;
            transform: scale(1.2);
          }
        }
      }
    }
    .think-list {
      background-color: #f6f6fe;
      padding: 60px 15%;
      h2 {
        font-size: 30px;
        & + span {
          color: #999;
        }
      }
      .think-row {
        display: flex;
        justify-content: space-between;
        text-align: left;
        margin: 40px 0;
        .think {
          width: 46%;
          border-radius: 6px;
          display: flex;
          flex-direction: column;
          padding: 10px 20px;
          background-color: #fff;
          position: relative;
          transition: all 0.2s;
          .think-name {
            font-size: 20px;
            border-bottom: 1px solid #cecece;
            padding: 10px 0;
            margin-bottom: 6px;
          }
          img {
            position: absolute;
            top: 20px;
            right: 20px;
          }
          &:hover {
            color: #fff;
            background-color: #4555f5;
          }
          &:nth-child(2) {
            img {
              width: 26px;
            }
          }
        }
      }
    }
    .flow-list {
      background-color: #fff;
      padding: 60px 15%;

      h2 {
        font-size: 30px;
        & + span {
          color: #999;
        }
      }
      .flows {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        text-align: left;

        .flow {
          flex: 1;
          padding-right: 60px;
          position: relative;
          &:last-child {
            padding-right: 0;
          }
          .flow-top {
            display: flex;
            font-size: 30px;
            font-weight: bold;
            margin-bottom: 20px;
            img {
              margin-left: 50px;
            }
          }
          .flow-name {
            font-size: 20px;
          }
          .anticon {
            position: absolute;
            right: 30px;
            top: 50%;
            color: #cecece;
          }
        }
      }
    }
  }
  footer {
    background-color: #000;
    padding: 60px 0 0;
    width: 100vw;
    transform: translateX(-15%);
    h2 {
      color: #fff;
      font-size: 30px;
      margin-bottom: 60px;
    }
    .add-comp-row {
      width: 60%;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;
      .add-comps {
        background-color: #fff;
        padding: 20px;
        margin: 0 20px;
        flex: 1;
        max-width: calc(25% - 40px);
        border-radius: 6px;
        img {
          width: 100%;
        }
      }
    }
    .logo-row {
      padding: 20px 15%;
      background-color: #fff;
      display: flex;
      justify-content: center;
      border-bottom: 1px solid #cecece;
    }
    .police-row {
      background-color: #fff;
      padding: 10px 0;
      a {
        color: #000;
        img {
          margin-right: 10px;
        }
      }
    }
  }
}
</style>
,<style lang="less">
.callModal {
  .ant-modal-body {
    padding: 0;
    height: 500px;
    overflow: hidden;
    .content {
      position: relative;
      display: flex;
      border-radius: 4px;
      .left {
        width: 50%;
        position: relative;
        background-image: url("@/assets/clip.jpg");
        img {
          z-index: 2;
        }
        .clip {
          position: absolute;
          z-index: 1;
          width: 200%;
          top: 0;
          left: 0;
        }
      }
      .right {
        padding: 60px;
        flex: 1;
        .concat {
          margin: 0 auto;
          text-align: center;
          font-size: 30px;
          margin-bottom: 30px;
        }
        .ant-input {
          margin-bottom: 20px;
        }
        .ant-radio-wrapper > span > span {
          color: #3f4cdb;
        }
        .ant-btn {
          width: 100%;
          height: 50px;
          margin-top: 50px;
          background-color: #3f4cdb;
        }
      }
    }
  }
  .ant-modal-footer {
    display: none;
  }
}
</style>